import React from "react";

/* Importing the page styles */
import "../styles/onlinedonation.css";

function DonationClosed() {
	return (
		<div className="online-donation">
			<div className="home-banner">
				<img
					src="/assets/images/atm_banner.png"
					title="ATM Global Group"
					width="800"
					loading="eager"
					height="180"
					alt=""
				/>
			</div>

			<div className="online-donation-root">
				<div className="online-head">
					<h2>ARUL THARUM MAHAPERIYAVA CHARITABLE TRUST (ATMC TRUST)</h2>
					<p>
						Registration and Contribution for Samashti Bikshavandanam 2024 is
						closed.
					</p>
					<p>
						Devotees may contribute to our various other activities through this
						online donation link which will be opened shortly.
					</p>
				</div>
			</div>
		</div>
	);
}

export default DonationClosed;
