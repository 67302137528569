import React from "react";

/* Importing styles */
import "../styles/joinus.css";

export default function JoinUs() {
	return (
		<section className="join" id="join">
			<div className="join-blob"></div>
			<div className="join-hero">
				<div className="join-left">
					<div className="join-hero-content">
						<h1>JOIN US FOR MORE UPDATES</h1>
						<div className="join-content">
							<img
								width="200"
								loading="lazy"
								title="ATM Global Group"
								height="150"
								src="./assets/icons/whatsapp_icon.svg"
								alt=""
							/>
							<div className="join-text">
								<h3>Whatsapp Group</h3>
								<p>
									To Join the group and be part of our initiatives,
									WhatsApp&nbsp;
									<strong>"ATM"</strong>&nbsp; to{" "}
									<a
										href="https://api.whatsapp.com/send?phone=8613472673759&text=ATM"
										target="_blank"
										rel="noopener noreferrer"
									>
										+8613472673759
									</a>
								</p>
							</div>
						</div>
					</div>
					<div className="join-hero-content">
						<h2>FOLLOW US</h2>
						<div className="join-content">
							<img
								width="200"
								loading="lazy"
								height="150"
								title="ATM Global Group"
								src="./assets/icons/facebook_icon.svg"
								alt=""
							/>
							<a
								target="__blank"
								rel="noopener noreferrer"
								href="https://www.facebook.com/groups/528581191697429/"
							>
								ATM Facebook Group ↗
							</a>
						</div>
					</div>
					<div className="join-hero-content">
						<h2>SUBSCRIBE</h2>
						<div className="join-content">
							<img
								width="200"
								title="ATM Global Group"
								loading="lazy"
								height="150"
								src="./assets/icons/youtube_icon.svg"
								alt=""
							/>
							<a
								target="__blank"
								rel="noopener noreferrer"
								href="https://www.youtube.com/c/arultharummahaperiyavagroup"
							>
								Youtube Channel ↗
							</a>
						</div>
					</div>

					<div className="join-hero-content">
						<h2>CONTACT US</h2>
						<div
							className="join-content"
							style={{
								flexDirection: "column",
								alignItems: "flex-start",
								gap: 0,
								lineHeight: 0,
							}}
						>
							<p>Mobile: +91 80727 54531 | +86 1347 2673 759</p>
							<p>
								Email:{" "}
								<a href="mailto:arultharummahaperiyava@gmail.com">
									arultharummahaperiyava@gmail.com
								</a>
							</p>
							<p>Address: I-4, Sivakami Apartments, </p>
							<p>Muthuvel Nagar, East Tambaram, </p>
							<p>Chennai - 600 059</p>
						</div>
					</div>
				</div>
				<div className="join-right">
					<img
						width="500"
						loading="lazy"
						title="ATM Global Group"
						height="250"
						src="./assets/images/join_us.webp"
						alt=""
					/>
				</div>
			</div>
			<footer className="join-footer">
				<h2 className="footer-text">
					Periyava Charanam, Periyava Charanam, Periyava Charanam
				</h2>
				<p style={{ fontSize: 18, textDecoration: "underline" }}>
					{" "}
					Quick Links
				</p>
				<div className="join-footer-content">
					<a href="/home">Home</a>
					<a href="/about-atm">About ATM</a>
					<a href="/activities">Our Activities</a>
					<a href="/gallery">Gallery</a>
					<a href="/announcements">Announcements</a>
					<a href="/terms">Terms and Conditions</a>
					<a href="/privacy">Privacy Policy</a>
				</div>
				<p className="join-footer-pcontent">
					Website hosted by Prudent Solutions ©️ {new Date().getFullYear()}
				</p>
			</footer>
		</section>
	);
}
